<template>
  <en-dialog :model-value="modelValue" width="80%" title="预约单" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax link :method="operation.export.click">导出</button-ajax>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVRVSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SVSRSV"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            async click() {
              return this.ajax('GET /enocloud/service/reservation/export', this.table.$ajaxParams)
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/reservation/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          CUSTOMER_NAME: { header: { filter: { type: 'text', field: 'customerName' } } },
          PREPARED_DATETIME: { header: { filter: { type: 'date', field: ['preparedStartDate', 'preparedEndDate'], props: { type: 'daterange' } } } },
          SOURCE: {
            header: {
              filter: {
                type: 'select',
                field: 'source',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          RESERVATION_DATETIME: {
            header: { filter: { type: 'date', field: ['reservationStartDate', 'reservationEndDate'], props: { type: 'daterange' } } }
          },
          COMMENT: { header: { filter: { type: 'text', field: 'comment' } } }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
